<template>
  <div :class="themeClass">
    <div class="allOrder-box margin-t-20">
      <div class="all-title font-size18 font-color-333 font-weight700">
        全部邀请
      </div>
      <div class="top flex-row-align-center">
        <div class="choose-date margin-l-10" style="width: 316px;display: flex;align-items: center;">
          <el-date-picker v-model="searchDate[0]" type="date" placeholder="请选择开始日期"></el-date-picker>
          <div style="margin-left: 3px;margin-right: 3px;">至</div>
          <el-date-picker v-model="searchDate[1]" type="date" placeholder="请选择结束日期"></el-date-picker>
        </div>
        <div class="search-box margin-l-10">
          <el-input prefix-icon="el-icon-search" v-model="searchText" placeholder="输入邀请人进行模糊查询"> </el-input>
        </div>
        <div class="pointer search-btn margin-l-10 text-center" @click="queryListData">搜索</div>
      </div>
      <div v-loading="loading" class="tab-content2">

        <el-table :data="fromData" stripe border :row-style="{ height: '40px' }"
          :header-cell-style="{ 'text-align': 'center', background: '#F2F2F2', 'font-weight': '400', color: '#000' }"
          height="530" v-if="fromData.length>0">
          <el-table-column type="index" width="50" align="center" label="序号" fixed />

          <el-table-column prop="fUserName" label="被邀请人" width="auto" align="left" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="fCreateTime" label="邀请时间" width="120" align="center"
            show-overflow-tooltip></el-table-column>

        </el-table>
      </div>
      <div class="pagination">
        <pagination :currentPage="currentPage" :pagesize="pagesize" :total="total"
          @handleCurrentChange="handleCurrentChange"></pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";
import pagination from "../../../components/pagination/pagination";
export default {
  name: "",
  components: {
    pagination
  },
  props: {},
  data () {
    return {
      searchDate: ['', ''],
      searchText: "",
      fromData: [],
      // 当前页数
      currentPage: 1,
      // 每页条数
      pagesize: 10,
      // 总条数
      total: 0,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getThemeName"]),
    themeClass () {
      return `theme-${this.getThemeName}`;
    },
  },
  mounted () {
    const start = new Date();
    const end = new Date();
    this.searchDate[0] = new Date(start.setTime(start.getTime() - 3600 * 1000 * 24 * 90))
    this.searchDate[1] = end
    this.queryListData();
  },
  methods: {
    // 当前页 改变时会触发
    handleCurrentChange (Current) {
      this.currentPage = Current;
      this.queryListData();
    },
    queryListData () {
      this.loading = true
      this.ApiRequestPostNOMess('api/mall/ebcustomer/baseinfo/get-invite-list-by-dateime', {
        fBeginDate: this.searchDate[0] == '' ? this.searchDate[0] : this.getDate(this.searchDate[0]),
        fEndDate: this.searchDate[1] == '' ? this.searchDate[1] : this.getDate(this.searchDate[1]),
        filter: this.searchText,
        maxResultCount: this.pagesize,
        skipCount: (this.currentPage - 1) * this.pagesize,
        sorting: '',
      }).then(res => {
        for (let item of res.obj.items) {
          item.fCreateTime = this.getTime(item.fCreateTime).slice(0,10);
        }
        this.total = res.obj.totalCount;
        this.fromData = res.obj.items
        console.log(this.fromData, '查看这些数据')
        this.loading = false
      }, error => { });
    }
  }
};
</script>

<style lang="scss" scoped>
.color-theme {
  @include themify($themes) {
    color: themed("themes_color");
  }
}

.background-color-theme {
  @include themify($themes) {
    background-color: themed("themes_color");
    color: themed("text_color_white");
  }
}

.border-color-theme {
  @include themify($themes) {
    border: 1px solid themed("themes_color");
  }
}

/deep/ [data-v-6ac52456] .el-input__icon {
  line-height: 22px;
}

/deep/ .el-tabs__item.is-active {
  color: #fff;

  @include themify($themes) {
    background-color: themed("themes_color");
  }
}

/deep/ [data-v-eb289ada] .el-input__icon {
  line-height: 22px;
}

/deep/ .el-date-editor .el-range-separator {
  line-height: 24px;
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 40px;
}

.allOrder-Box {
  height: 100%;
  background-color: #ff3040;
}

.tab-content2 {
  margin-top: 10px;
  width: 100%;
  // height: 400px;
  // overflow-y: scroll;
  // background-color: red;
}
.all-title {
  height: 24px;
  line-height: 24px;
  margin-bottom: 4px;
}

.top {
  width: 100%;
  height: 32px;

  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 145px;
  }

  .search-box {
    width: 480px;
  }

  .search-btn {
    width: 79px;
    height: 32px;
    line-height: 32px;
    background: #f8f7fa;
    border-radius: 4px;
    border: 1px solid #f2f2f2;
  }
}

/deep/ .el-tabs__header {
  margin: 0;
}

/deep/ .el-tabs__item {
  padding: 0;
  width: 75px;
  height: 32px;
  line-height: 32px;
  text-align: center;
}

/deep/ .el-tabs__active-bar {
  display: none;
}

/deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}

/deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}

/deep/ .el-input__icon {
  line-height: 22px;
}

.title {
  width: 100%;
  height: 35px;
  background: #f8f9fe;
}

.content {
  .nav {
    width: 100%;
    height: 35px;
    line-height: 35px;
    background: #f8f9fe;

    span {
      display: block;
      text-align: center;

      &:nth-child(1) {
        width: 425px;
      }

      &:nth-child(n + 2) {
        width: 123px;
      }
    }
  }
}

.pagination {
  margin: 10px 0px;
  float: right;
}
</style>
